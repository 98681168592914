import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/Layout/Layout.vue";

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	component: Layout,
	redirect: '/home',
	children: [{
		path: '/home',
		name: 'home',
		component: () => import('../views/home.vue')
	},
	{
		path: '/account-manag',
		name: 'account-manag',
		component: () => import('../views/account-manag.vue')
	},
	{
		path: '/bill-manag',
		name: 'bill-manag',
		component: () => import('../views/bill-manag.vue')
	},
	{
		path: '/receiptacc',
		name: 'receiptacc',
		component: () => import('../views/receiptacc.vue')
	},
	{
		path: '/corppay-manag',
		name: 'corppay-manag',
		component: () => import('../views/corppay-manag.vue')
	},
	{
		path: '/order-manag',
		name: 'order-manag',
		component: () => import('../views/order-manag.vue')
	},
	{
		path: '/report-manag',
		name: 'report-manag',
		component: () => import('../views/report-manag.vue')
	},
	{
		path: '/news-manag',
		name: 'news-manag',
		component: () => import('../views/news-manag.vue')
	},
	{
		path: '/equipment-status',
		name: 'equipment-status',
		component: () => import('../views/equipment-status.vue')
	},
	{
		path: '/trade-manag',
		name: 'trade-manag',
		component: () => import('../views/trade-manag.vue')
	},
	{
		path: '/news-cate',
		name: 'news-cate',
		component: () => import('../views/news-cate.vue')
	},
	{
		path: '/equipment-detail',
		name: 'equipment-detail',
		component: () => import('../views/equipment-detail.vue')
	},
	{
		path: '/account-inquiry',
		name: 'account-inquiry',
		component: () => import('../views/account-inquiry.vue')
	},
	{
		path: '/user-manag',
		name: 'user-manag',
		component: () => import('../views/user-manag.vue')
	},
	{
		path: '/reserve-manag',
		name: 'reserve-manag',
		component: () => import('../views/reserve-manag.vue')
	},
	{
		path: '/admin-manag',
		name: 'admin-manag',
		component: () => import('../views/admin-manag.vue')
	},
	{
		path: '/corp-manag',
		name: 'corp-manag',
		component: () => import('../views/corp-manag.vue')
	},
	{
		path: '/corpinfo-manag',
		name: 'corpinfo-manag',
		component: () => import('../views/corpinfo-manag.vue')
	},
	{
		path: '/bank-manag',
		name: 'bank-manag',
		component: () => import('../views/bank-manag.vue')
	},
	{
		path: '/bank-outlets',
		name: 'bank-outlets',
		component: () => import('../views/bank-outlets.vue')
	},
	{
		path: '/router-manag',
		name: 'router-manag',
		component: () => import('../views/router-manag.vue')
	},
	{
		path: '/role-manag',
		name: 'role-manag',
		component: () => import('../views/role-manag.vue')
	},
	{
		path: '/change-password',
		name: 'change-password',
		component: () => import('../views/change-password.vue')
	},
	{
		path: '/equipment-manag',
		name: 'equipment-manag',
		component: () => import('../views/equipment-manag.vue')
	},
	{
		path: '/equipment-types',
		name: 'equipment-types',
		component: () => import('../views/equipment-types.vue')
	},
	{
		path: '/setting',
		name: 'setting',
		component: () => import('../views/setting.vue')
	},
	]
},
{
	path: '/login',
	name: 'login',
	component: () => import('../views/login.vue')
},

]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {

	if (to.name !== 'login' && !localStorage.getItem("token")) {
		next({
			name: 'login'
		})
	} else {
		next()
		if (localStorage.getItem('authorityList')) {
			let authority = JSON.parse(localStorage.getItem('authorityList'))
			if (authority.includes('/' + to.name) || to.name == 'login') {
				next();
			} else {
				// next()
				if (to.name == 'home') {
					next({
						name: authority[0].slice(1)
					})
				} else if (to.name == 'login') {
					next({
						name: 'login'
					})
				} else {
					localStorage.removeItem('username')
					localStorage.removeItem('token')
					localStorage.removeItem('authority')
					localStorage.removeItem('authorityList')
					window.location.href = '#/login'
				}
			}
		} else {
			next()
		}
	}
})

export default router
